export const fonts = [
  { name: "Roboto" },
  { name: "Open Sans" },
  { name: "Josefin Slab" },
  { name: "Arvo" },
  { name: "Lato" },
  { name: "Abril Fatface" },
  { name: "Ubuntu" },
  { name: "PT Sans" },
  { name: "Old Standard TT" },
  { name: "Droid Sans" },
  { name: "Sansita" },
];
